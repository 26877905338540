<script>
	import { base } from '$app/paths';
	import styles from './HeaderBar.module.css';
</script>

<header class={styles.header}>
	<div class={styles.left}>
		<a class={styles.logo} href={base || '/'}>
			<slot name="logo" />
		</a>
	</div>
	<div class={styles.middle}><slot name="middle" /></div>
	<div class={styles.right}><slot name="right" /></div>
</header>
<div class={styles.spacer}></div>
